var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "realLoadRate-page" },
    [
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.dialogTitle,
                visible: _vm.dialogVisible,
                "close-on-click-modal": false,
                width: "30%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "200px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "实载率起（不含）%：",
                        prop: "realLoadRateStart",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        on: {
                          input: () =>
                            (_vm.ruleForm.realLoadRateStart =
                              _vm.ruleForm.realLoadRateStart.replace(
                                /\D/g,
                                ""
                              )),
                        },
                        model: {
                          value: _vm.ruleForm.realLoadRateStart,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "realLoadRateStart", $$v)
                          },
                          expression: "ruleForm.realLoadRateStart",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "实载率止（含）%：",
                        prop: "realLoadRateEnd",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        on: {
                          input: () =>
                            (_vm.ruleForm.realLoadRateEnd =
                              _vm.ruleForm.realLoadRateEnd.replace(/\D/g, "")),
                        },
                        model: {
                          value: _vm.ruleForm.realLoadRateEnd,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "realLoadRateEnd", $$v)
                          },
                          expression: "ruleForm.realLoadRateEnd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "积分值：", prop: "integralValue" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        on: {
                          input: () =>
                            (_vm.ruleForm.integralValue =
                              _vm.ruleForm.integralValue.replace(/\D/g, "")),
                        },
                        model: {
                          value: _vm.ruleForm.integralValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "integralValue", $$v)
                          },
                          expression: "ruleForm.integralValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.confirmFun },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }