<template>
  <div class="realLoadRate-page">
    <el-dialog
      v-if="dialogVisible"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="30%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="200px"
        class="demo-ruleForm"
      >
        <el-form-item label="实载率起（不含）%：" prop="realLoadRateStart">
          <el-input
            v-model="ruleForm.realLoadRateStart"
            placeholder="请输入"
            @input="
              () =>
                (ruleForm.realLoadRateStart =
                  ruleForm.realLoadRateStart.replace(/\D/g, ''))
            "
          ></el-input>
        </el-form-item>
        <el-form-item label="实载率止（含）%：" prop="realLoadRateEnd">
          <el-input
            v-model="ruleForm.realLoadRateEnd"
            placeholder="请输入"
            @input="
              () =>
                (ruleForm.realLoadRateEnd = ruleForm.realLoadRateEnd.replace(
                  /\D/g,
                  ''
                ))
            "
          ></el-input>
        </el-form-item>
        <el-form-item label="积分值：" prop="integralValue">
          <el-input
            v-model="ruleForm.integralValue"
            placeholder="请输入"
            @input="
              () =>
                (ruleForm.integralValue = ruleForm.integralValue.replace(
                  /\D/g,
                  ''
                ))
            "
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmFun">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  saveDriverRealLoadRateAPI,
  updateDriverRealLoadRateAPI,
} from "@/api/lib/api.js";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      dialogTitle: "",
      ruleForm: {
        realLoadRateStart: "",
        realLoadRateEnd: "",
        integralValue: "",
      },
      rules: {
        realLoadRateStart: [
          { required: true, message: "请输入实载率起", trigger: "blur" },
        ],
        realLoadRateEnd: [
          { required: true, message: "请输入实载率止", trigger: "blur" },
        ],
        integralValue: [
          { required: true, message: "请输入积分值", trigger: "blur" },
        ],
      },
    };
  },
  filters: {},
  computed: {},
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.ruleForm = this.$options.data().ruleForm;
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    openDialogFun(title, row) {
      this.ruleForm = JSON.parse(JSON.stringify(row));
      this.dialogTitle = title;
      this.dialogVisible = true;
    },
    /**
     * @description 确认
     */
    confirmFun() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let path =
            this.dialogTitle === "添加实载率"
              ? saveDriverRealLoadRateAPI
              : updateDriverRealLoadRateAPI;
          path(this.ruleForm).then((res) => {
            if (res.code === "SUCCESS") {
              this.dialogVisible = false;
              this.$parent.getQueryDriverRealLoadRateList();
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.realLoadRate-page {
  .demo-ruleForm {
    .el-input {
      width: 200px;
    }
  }
}
</style>
