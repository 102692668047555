<template>
  <!-- 司机考核配置 -->
  <div v-loading="pageLoading" class="driverConfiguration-page">
    <template v-if="channelList.length">
      <el-tabs v-model="channelNo" type="card" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in channelList"
          :key="index"
          :label="item.channelName"
          :name="item.channelNo"
        >
        </el-tab-pane>
      </el-tabs>
      <!-- 服务满意度 -->
      <div class="service-satisfaction">
        <h2>服务满意度</h2>
        <Table
          :table-data="serviceSatisfactionData"
          :title-name="serviceSatisfactionTitleName"
          operation
          col-class-type
          operation-width="50"
        >
          <template slot-scope="scope">
            <div>
              <template>
                <el-button
                  v-if="listFind('编辑服务满意度')"
                  type="text"
                  size="small"
                  sort="primary"
                  @click="editServiceSatisfactionFun(scope.scopeRow)"
                  >编辑</el-button
                >
              </template>
            </div>
          </template>
        </Table>
        <div class="handle-box">
          <div>基础分：</div>
          <el-input
            v-model="baseIntegralValue"
            placeholder="请输入"
            @input="handleInput($event, 'baseIntegralValue')"
          ></el-input>
          <el-button
            v-if="listFind('保存服务满意度')"
            type="primary"
            size="small"
            @click="saveServiceSatisfactionFun"
            >保存</el-button
          >
        </div>
      </div>
      <!-- 实载率 -->
      <div class="real-load-rate">
        <h2>实载率</h2>
        <el-button
          v-if="listFind('添加实载率')"
          type="primary"
          size="small"
          style="margin-bottom: 20px"
          @click="
            handleRealLoadRateFun('添加实载率', {
              channelNo,
              realLoadRateStart: '',
              realLoadRateEnd: '',
              integralValue: '',
            })
          "
          >添加</el-button
        >
        <Table
          :table-data="realLoadRateData"
          :title-name="realLoadRateTitleName"
          operation
          col-class-type
          operation-width="100"
        >
          <template slot-scope="scope">
            <div v-if="realLoadRateData.length && scope.scopeRow.$index === 0">
              <template>
                <el-button
                  v-if="listFind('编辑实载率')"
                  type="text"
                  size="small"
                  sort="primary"
                  @click="handleRealLoadRateFun('编辑实载率', scope.scopeRow)"
                  >编辑</el-button
                >
              </template>
              <template>
                <el-button
                  v-if="listFind('删除实载率')"
                  type="text"
                  size="small"
                  sort="primary"
                  @click="deleteRealLoadRateFun(scope.scopeRow.id)"
                  >删除</el-button
                >
              </template>
            </div>
          </template>
        </Table>
        <div class="handle-box">
          <div>基础分：</div>
          <el-input
            v-model="realLoadRateObj.baseIntegralValue"
            placeholder="请输入"
            @input="handleInput($event, 'realLoadRateObj')"
          ></el-input>
          <div>计算方式：</div>
          <el-select
            v-model="realLoadRateObj.calculationMethod"
            @change="changeCalculationMethodFun"
          >
            <el-option
              v-for="item in calculationMethodOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div>入账方式：</div>
          <el-select v-model="realLoadRateObj.collectMethod">
            <el-option
              v-for="item in collectMethodOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button
            v-if="listFind('保存实载率')"
            type="primary"
            size="small"
            @click="saveRealLoadRateFun"
            >保存</el-button
          >
        </div>
      </div>
      <!-- 拉新积分兑换 -->
      <!-- <div class="redemption-of-points">
      <h2>拉新积分兑换</h2>
      <div class="handle-box">
        <div>启用兑换：</div>
        <el-switch
          v-model="integralObj.status"
          :active-value="0"
          :inactive-value="1"
          inactive-color="#E3E3E3"
          active-color="#13ce66"
        >
        </el-switch>
        <div>兑换比例：</div>
        <el-input
          v-model="integralObj.convertRatioStart"
          placeholder="请输入"
          @input="
            () =>
              (integralObj.convertRatioStart =
                integralObj.convertRatioStart.replace(/^(0+)|[^\d]+/g, ''))
          "
        ></el-input>
        <span style="margin: 0 10px">:</span>
        <el-input
          v-model="integralObj.convertRatioEnd"
          placeholder="请输入"
          @input="
            () =>
              (integralObj.convertRatioEnd =
                integralObj.convertRatioEnd.replace(/^(0+)|[^\d]+/g, ''))
          "
        ></el-input>
        <span>（用户积分 : 司机积分）</span>
        <el-button
          v-if="listFind('保存拉新积分兑换')"
          type="primary"
          size="small"
          @click="saveRedemptionOfPointsFun"
          >保存</el-button
        >
      </div>
    </div> -->
      <!-- 拉新积分 -->
      <div style="font-size: 14px" class="lagrangian-integral">
        <h2>拉新积分</h2>
        <div style="margin-bottom: 20px">
          <span>活动开关：</span>
          <el-switch
            v-model="pullIntegralObj.status"
            :active-value="0"
            :inactive-value="1"
            inactive-color="#E3E3E3"
            active-color="#13ce66"
            :disabled="
              !pullIntegralObj.userCount || !pullIntegralObj.integralValue
            "
          >
          </el-switch>
        </div>
        <div>
          <span>活动奖励：</span>
          每邀请
          <el-input
            v-model="pullIntegralObj.userCount"
            placeholder="请输入1-10整数"
            @input="changeInputFun($event, 'userCount', 10)"
          ></el-input>
          名新用户
          <el-select v-model="pullIntegralObj.activityType">
            <el-option
              v-for="item in eventRewardsList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          ，奖励
          <el-input
            v-model="pullIntegralObj.integralValue"
            placeholder="请输入1-10000整数"
            @input="changeInputFun($event, 'integralValue', 10000)"
          ></el-input>
          积分
          <el-button
            v-if="listFind('保存拉新积分')"
            type="primary"
            size="small"
            @click="savePullIntegralFun"
            >保存</el-button
          >
        </div>
      </div>
    </template>
    <div
      v-else-if="emptyFlag"
      style="width: 100%; font-size: 18px; text-align: center"
    >
      <img src="~@/assets/images/home/empty.png" alt />
      <div>暂无权限，请联系管理员</div>
    </div>
    <serviceSatisfactionDialog
      ref="serviceSatisfactionRef"
    ></serviceSatisfactionDialog>
    <realLoadRateDialog ref="realLoadRateDialogRef"></realLoadRateDialog>
  </div>
</template>

<script>
import {
  getCurrentUserChannelInfoAPI,
  queryDriverServiceSatisfactionListAPI,
  queryDriverServiceSatisfactionBaseIntegralInfoAPI,
  updateDriverServiceSatisfactionBaseIntegralAPI,
  queryDriverRealLoadRateListAPI,
  queryDriverRealLoadRateBaseIntegralInfoAPI,
  updateDriverRealLoadRateBaseIntegralAPI,
  deleteDriverRealLoadRateByIdAPI,
  queryDriverInviteNewUserIntegralConvertInfoAPI,
  updateDriverInviteNewUserIntegralConvertAPI,
  queryInfoByChannelAPI,
  updatePullNewIntegralRuleAPI,
} from "@/api/lib/api.js";
import serviceSatisfactionDialog from "./components/serviceSatisfactionDialog.vue";
import realLoadRateDialog from "./components/realLoadRateDialog.vue";
export default {
  name: "",
  components: {
    serviceSatisfactionDialog,
    realLoadRateDialog,
  },
  props: {},
  data() {
    return {
      pageLoading: true,
      emptyFlag: false,
      channelNo: "",
      channelName: "",
      channelList: [],
      //服务满意度
      baseIntegralValue: "",
      serviceSatisfactionData: [],
      serviceSatisfactionTitleName: [
        {
          title: "评价来源",
          props: "evaluationSource",
          SpecialJudgment: (res) => {
            return res == 0 ? "用户" : res == 1 ? "客服" : "";
          },
        },
        {
          title: "评价等级",
          props: "evaluationGrade",
          SpecialJudgment: (res) => {
            let str = "";
            switch (res) {
              case 0:
                str = "好评";
                break;
              case 1:
                str = "中评";
                break;
              case 2:
                str = "差评";
                break;
              case 3:
                str = "投诉";
                break;
              case 4:
                str = "违规";
                break;
              default:
                str = "";
                break;
            }
            return str;
          },
        },
        {
          title: "积分值",
          props: "integralValue",
        },
        {
          title: "当日积分上限",
          props: "sameDayMaxIntegralValue",
        },
        {
          title: "是否折算",
          props: "isConversion",
          slotType: "header",
          tooltip: "折算公式：每评价积分值 = 当日积分上限 / Σ(当日额定客位数)",
          SpecialJudgment: (res) => {
            return res == 0 ? "否" : res == 1 ? "是" : "";
          },
        },
        {
          title: "评价确认方式",
          props: "evaluationConfirmMethod",
          SpecialJudgment: (res) => {
            return res == 0 ? "系统确认" : res == 1 ? "人工确认" : "";
          },
        },
        {
          title: "积分规则",
          props: "integralRule",
          SpecialJudgment: (res) => {
            return res == 0 ? "按票位" : res == 1 ? "按订单" : "";
          },
        },
        {
          title: "入账方式",
          props: "integralCollectMethod",
          SpecialJudgment: (res) => {
            return res == -1
              ? "不入账"
              : res == 0
              ? "实时入账"
              : res == 1
              ? "次日汇总入账"
              : "";
          },
        },
      ],
      //实载率
      realLoadRateObj: {
        baseIntegralValue: "",
        calculationMethod: "",
        collectMethod: "",
      },
      realLoadRateData: [],
      realLoadRateTitleName: [
        {
          title: "实载率起（不含）%",
          props: "realLoadRateStart",
        },
        {
          title: "实载率止（含）%",
          props: "realLoadRateEnd",
        },
        {
          title: "积分值",
          props: "integralValue",
        },
      ],
      calculationMethodOptions: [
        { label: "按天计算", value: 0 },
        { label: "按班次计算", value: 1 },
      ],
      collectMethodOptions: [
        //   { label: "次日汇总入账", value: 1 },
        //   { label: "实时入账", value: 0 },
        //   { label: "不入账", value: -1 },
      ],
      //拉新积分兑换
      integralObj: {
        status: 1,
        convertRatioStart: "", //用户积分
        convertRatioEnd: "", //司机积分
      },
      //拉新积分
      pullIntegralObj: {
        status: 1,
        activityType: null,
        channelNo: this.channelNo,
        userCount: "",
        integralValue: "",
      },
      eventRewardsList: [
        { label: "注册", value: 1 },
        { label: "注册并下单", value: 2 },
      ],
    };
  },
  filters: {},
  computed: {
    pullIntegralObjStatus() {
      return !(
        this.pullIntegralObj.userCount && this.pullIntegralObj.integralValue
      );
    },
  },
  watch: {
    "realLoadRateObj.calculationMethod"(val) {
      if (val) {
        this.collectMethodOptions = [
          { label: "实时入账", value: 0 },
          { label: "不入账", value: -1 },
        ];
      } else {
        this.collectMethodOptions = [
          { label: "次日汇总入账", value: 1 },
          { label: "不入账", value: -1 },
        ];
      }
    },
    pullIntegralObjStatus(val) {
      if (val) {
        this.pullIntegralObj.status = 1;
      }
    },
  },
  created() {
    this.getQueryChannelList();
  },
  mounted() {},
  methods: {
    /**
     * @description 拉新积分
     */
    getQueryInfoByChannel() {
      queryInfoByChannelAPI({ channelNo: this.channelNo }).then((res) => {
        if (res.code === "SUCCESS") {
          this.pullIntegralObj = res.data;
          this.pullIntegralObj.channelNo = this.channelNo;
          if (res.data.status == null) {
            this.pullIntegralObj.status = 1;
          }
        }
      });
    },
    savePullIntegralFun() {
      if (
        this.pullIntegralObj.status == 0 &&
        (!this.pullIntegralObj.activityType ||
          !this.pullIntegralObj.userCount ||
          !this.pullIntegralObj.integralValue)
      ) {
        this.$message.warning("请先填写活动奖励");
        return;
      }
      updatePullNewIntegralRuleAPI(this.pullIntegralObj).then((res) => {
        if (res.code === "SUCCESS" && res.data) {
          this.$message.success("保存成功");
          this.getQueryInfoByChannel();
        }
      });
    },
    changeInputFun(val, key, max) {
      this.pullIntegralObj[key] = val.replace(/^(0+)|[^\d]+/g, "");
      this.pullIntegralObj[key] =
        this.pullIntegralObj[key] > max ? max : this.pullIntegralObj[key];
    },
    /**
     * @description 获取服务满意度数据
     */
    getQueryDriverServiceSatisfactionList() {
      queryDriverServiceSatisfactionListAPI({ channelNo: this.channelNo }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.serviceSatisfactionData = res.data;
            this.getQueryDriverServiceSatisfactionBaseIntegralInfo();
          }
        }
      );
    },
    /**
     * @description 获取司机考服务满意度基础积分表详情
     */
    getQueryDriverServiceSatisfactionBaseIntegralInfo() {
      queryDriverServiceSatisfactionBaseIntegralInfoAPI({
        channelNo: this.channelNo,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.baseIntegralValue = res.data.baseIntegralValue;
        } else {
          this.baseIntegralValue = "";
        }
      });
    },
    /**
     * @description 保存服务满意度
     */
    saveServiceSatisfactionFun() {
      updateDriverServiceSatisfactionBaseIntegralAPI({
        channelNo: this.channelNo,
        baseIntegralValue: this.baseIntegralValue,
      }).then((res) => {
        if (res.code === "SUCCESS" && res.data) {
          this.$message.success("保存成功");
          this.getQueryDriverServiceSatisfactionList();
        }
      });
    },
    /**
     * @description 编辑服务满意度
     */
    editServiceSatisfactionFun(row) {
      this.$refs.serviceSatisfactionRef.openDialogFun(row);
    },
    /**
     * @description 获取司机实载率列表
     */
    getQueryDriverRealLoadRateList() {
      queryDriverRealLoadRateListAPI({ channelNo: this.channelNo }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.realLoadRateData = res.data;
            this.getQueryDriverRealLoadRateBaseIntegralInfo();
          }
        }
      );
    },
    /**
     * @description 获取司机考核实载率计算方式详情
     */
    getQueryDriverRealLoadRateBaseIntegralInfo() {
      queryDriverRealLoadRateBaseIntegralInfoAPI({
        channelNo: this.channelNo,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.realLoadRateObj = JSON.parse(JSON.stringify(res.data));
        }
      });
    },
    /**
     * @description 操作实载率弹窗
     */
    handleRealLoadRateFun(title, row) {
      this.$refs.realLoadRateDialogRef.openDialogFun(title, row);
    },
    /**
     * @description 删除实载率
     */
    deleteRealLoadRateFun(id) {
      this.$confirm("是否删除该实载率数据？", "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteDriverRealLoadRateByIdAPI({ id }).then((res) => {
            if (res.code === "SUCCESS" && res.data) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getQueryDriverRealLoadRateList();
            }
          });
        })
        .catch(() => {});
    },
    changeCalculationMethodFun() {
      this.realLoadRateObj.collectMethod = -1;
    },
    /**
     * @description 保存实载率
     */
    saveRealLoadRateFun() {
      this.realLoadRateObj.channelNo = this.channelNo;
      updateDriverRealLoadRateBaseIntegralAPI(this.realLoadRateObj).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.$message.success("保存成功");
            this.getQueryDriverRealLoadRateList();
          }
        }
      );
    },
    /**
     * @description 获取拉新积分兑换配置详情
     */
    getQueryDriverInviteNewUserIntegralConvertInfo() {
      queryDriverInviteNewUserIntegralConvertInfoAPI({
        channelNo: this.channelNo,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.integralObj = JSON.parse(JSON.stringify(res.data));
        }
      });
    },
    /**
     * @description 保存拉新积分兑换
     */
    saveRedemptionOfPointsFun() {
      updateDriverInviteNewUserIntegralConvertAPI(this.integralObj).then(
        (res) => {
          if (res.code == "SUCCESS" && res.data) {
            this.$message.success("保存成功");
            this.getQueryDriverInviteNewUserIntegralConvertInfo();
          }
        }
      );
    },
    //点击渠道tab项
    handleClick(val) {
      this.channelName = val.label;
      this.getQueryDriverServiceSatisfactionList();
      this.getQueryDriverRealLoadRateList();
      this.getQueryDriverInviteNewUserIntegralConvertInfo();
      this.getQueryInfoByChannel();
    },
    //获取渠道数据
    getQueryChannelList() {
      getCurrentUserChannelInfoAPI()
        .then((res) => {
          if (res.code == "SUCCESS") {
            this.channelList = res.data;
            this.channelNo = res.data[0]?.channelNo;
            this.channelName = res.data[0]?.channelName;
            if (!res.data.length) {
              this.$message.error("暂无权限，请联系管理员");
              this.emptyFlag = true;
              this.pageLoading = false;
              return;
            }
            this.pageLoading = false;
            this.getQueryDriverServiceSatisfactionList();
            this.getQueryDriverRealLoadRateList();
            this.getQueryDriverInviteNewUserIntegralConvertInfo();
            this.getQueryInfoByChannel();
          }
        })
        .catch(() => {
          this.pageLoading = false;
        });
    },
    //正负整数过滤
    handleInput(e, val) {
      let value = e.replace(/[^\-\d]/g, ""); // 只能输入-和数字
      value = value.replace(/\-{2,}/g, "-"); // -只能保留一个
      value = value.replace(/(\d)\-/g, "$1"); // 数字后面不能接-，不能出现类似-11-2,12-，11-23
      value = value.replace(/-(0+)/g, "0"); // 不能出现-0,-001,-0001类似
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      if (val === "realLoadRateObj") {
        this[val].baseIntegralValue = value;
      } else {
        this[val] = value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.driverConfiguration-page {
  width: 100%;
  height: 100%;
  background-color: #fff;
  .handle-box {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 20px;
    .el-input,
    .el-select {
      width: 200px;
      margin-right: 10px;
    }
    .el-switch {
      margin-right: 10px;
    }
  }
  .service-satisfaction,
  .real-load-rate,
  .redemption-of-points,
  .lagrangian-integral {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  .redemption-of-points {
    .el-input {
      width: 80px !important;
      margin-right: 0 !important;
    }
  }
  .lagrangian-integral {
    .el-input,
    .el-select {
      width: 160px;
    }
  }
}
</style>
