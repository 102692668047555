<template>
  <div class="serviceSatisfaction-page">
    <el-dialog
      v-if="dialogVisible"
      title="编辑服务满意度"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="30%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        class="demo-ruleForm"
      >
        <el-form-item label="评价来源：" prop="evaluationSource">
          <span>{{
            ruleForm.evaluationSource == 0
              ? "用户"
              : ruleForm.evaluationSource == 1
              ? "客服"
              : ""
          }}</span>
        </el-form-item>
        <el-form-item label="评价等级：" prop="evaluationGrade">
          <span>{{
            ruleForm.evaluationGrade == 0
              ? "好评"
              : ruleForm.evaluationGrade == 1
              ? "中评"
              : ruleForm.evaluationGrade == 2
              ? "差评"
              : ruleForm.evaluationGrade == 3
              ? "投诉"
              : ruleForm.evaluationGrade == 4
              ? "违规"
              : ""
          }}</span>
        </el-form-item>
        <el-form-item label="积分值：" prop="integralValue">
          <el-input
            v-model="ruleForm.integralValue"
            placeholder="请输入"
            @input="handleInput"
          ></el-input>
        </el-form-item>
        <el-form-item label="当日积分上限：" prop="sameDayMaxIntegralValue">
          <el-input
            v-model="ruleForm.sameDayMaxIntegralValue"
            placeholder="请输入"
            @input="
              () =>
                (ruleForm.sameDayMaxIntegralValue =
                  ruleForm.sameDayMaxIntegralValue
                    .replace(/[^\d]+/g, '')
                    .replace(/^0+(\d)/, '$1'))
            "
          ></el-input>
        </el-form-item>
        <el-form-item label="是否折算：" prop="isConversion">
          <el-select v-model="ruleForm.isConversion">
            <el-option
              v-for="item in isConversionOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评价确认方式：" prop="evaluationConfirmMethod">
          <el-select v-model="ruleForm.evaluationConfirmMethod">
            <el-option
              v-for="item in evaluationConfirmMethodOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="积分规则：" prop="integralRule">
          <el-select v-model="ruleForm.integralRule">
            <el-option
              v-for="item in integralRuleOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入账方式：" prop="integralCollectMethod">
          <el-select v-model="ruleForm.integralCollectMethod">
            <el-option
              v-for="item in integralCollectMethodOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmFun">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updateDriverServiceSatisfactionAPI } from "@/api/lib/api.js";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        evaluationSource: "",
        evaluationGrade: "",
        integralValue: "",
        sameDayMaxIntegralValue: "",
        isConversion: "",
        evaluationConfirmMethod: "",
        integralRule: "",
        integralCollectMethod: "",
      },
      rules: {
        evaluationSource: [
          { required: true, message: "请输入评价来源", trigger: "blur" },
        ],
        evaluationGrade: [
          { required: true, message: "请输入评价等级", trigger: "blur" },
        ],
        integralValue: [
          { required: true, message: "请输入积分值", trigger: "blur" },
        ],
        sameDayMaxIntegralValue: [
          { required: true, message: "请输入当日积分上限", trigger: "blur" },
        ],
        isConversion: [
          { required: true, message: "请选择是否折算", trigger: "change" },
        ],
        evaluationConfirmMethod: [
          { required: true, message: "请选择评价确认方式", trigger: "change" },
        ],
        integralRule: [
          { required: true, message: "请选择积分规则", trigger: "change" },
        ],
        integralCollectMethod: [
          { required: true, message: "请选择入账方式", trigger: "change" },
        ],
      },
      isConversionOptions: [
        { label: "是", value: 1 },
        { label: "否", value: 0 },
      ],
      evaluationConfirmMethodOptions: [
        { label: "系统确认", value: 0 },
        { label: "人工确认", value: 1 },
      ],
      integralRuleOptions: [
        { label: "按票位", value: 0 },
        { label: "按订单", value: 1 },
      ],
      integralCollectMethodOptions: [
        { label: "不入账", value: -1 },
        { label: "实时入账", value: 0 },
        { label: "次日汇总入账", value: 1 },
      ],
    };
  },
  filters: {},
  computed: {},
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.ruleForm = this.$options.data().ruleForm;
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    openDialogFun(row) {
      this.ruleForm = JSON.parse(JSON.stringify(row));
      this.dialogVisible = true;
    },
    /**
     * @description 确认
     */
    confirmFun() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          updateDriverServiceSatisfactionAPI(this.ruleForm).then((res) => {
            if (res.code === "SUCCESS" && res.data) {
              this.dialogVisible = false;
              this.$parent.getQueryDriverServiceSatisfactionList();
            }
          });
        }
      });
    },
    //正负整数过滤
    handleInput(e) {
      let value = e.replace(/[^\-\d]/g, ""); // 只能输入-和数字
      value = value.replace(/\-{2,}/g, "-"); // -只能保留一个
      value = value.replace(/(\d)\-/g, "$1"); // 数字后面不能接-，不能出现类似-11-2,12-，11-23
      value = value.replace(/-(0+)/g, "0"); // 不能出现-0,-001,-0001类似
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.ruleForm.integralValue = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.serviceSatisfaction-page {
  .demo-ruleForm {
    .el-input,
    .el-select {
      width: 300px;
    }
  }
}
</style>
